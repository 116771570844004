@font-face {
  font-family: 'Libre Franklin';
  src: local('Libre Franklin'), url('./fonts//LibreFranklin-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Libre Franklin Bold';
  src: local('Libre Franklin'), url('./fonts//LibreFranklin-Bold.ttf') format('truetype');
}

html body {
  margin: 0 !important;
  padding: 0 !important;
}
